import React, { Fragment,useState,useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import ImageUploader from 'react-images-upload';
import axios from 'axios'
import { Password,EditProfile,Name,Username,UpdateProfile,EmailAddress, Phone} from '../../constant'
import { toast } from 'react-toastify';
import '../../assets/scss/app-styles/common.scss'

const UserEdit = () => {

    const url = localStorage.getItem('profileURL');
    
    const [image,setimage] = useState({ pictureFiles: [] })
    const [data, setData] = useState({name:"",username:"", id:"", phone_number:"",email:""});

    const [password, setPassword] = useState({old_password:"",new_password:""});

    const [error, setError] = useState({prof_err:"",pass_err:""});
    const [attr, setAttr] = useState({prof_attr:"",pass_attr:""});
    const [style,setStyle] = useState('custom-spinner');

    const uname = JSON.parse(localStorage.getItem('creds')).username;
    const cld_id = JSON.parse(localStorage.getItem('creds')).cloud_id;
    const id = JSON.parse(localStorage.getItem('creds')).id;
    const user_type = JSON.parse(localStorage.getItem('creds')).user_type;

    const header = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }
    const updateProfileField = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const updatePasswordField = e => {
        setPassword({
            ...password,
            [e.target.name]: e.target.value
        });
    };
    
    const onDrop = (pictureFiles, pictureDataURLs) => {
        setimage({...image,pictureFiles
        });
    }


    useEffect(() => {
        const payload = {
            'username':uname,
            'cloud_id':cld_id,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        };
        setStyle('custom-spinner');
        axios.post(`${process.env.REACT_APP_API_URL}getProfileDetails`,payload,header)
        .then(response =>{ 

            if(response.status === 200){
                setData(response.data);
                setStyle('custom-spinner-hide');
            }
            
        })
        .catch(err=>{
            
            console.log(err);
            
        
        });
    },[])


    const updateProfileHandler = (e) => {
        // axios call here 
        //TODO:  also get profile image here of user and store it in profileURL
        e.preventDefault();
        
        let payLoad = new FormData();
            payLoad.append('name', data.name);
            payLoad.append('username', data.username);
            payLoad.append('id', id);
            payLoad.append('phone_number', data.phone_number);
            payLoad.append('email', data.email);
            if(image.pictureFiles.length>0){
                payLoad.append('type', 'image');
                payLoad.append('file', image.pictureFiles[0]);

            }else{
                payLoad.append('type', 'text');
            
            }
        
            setAttr({...attr, prof_attr:"disabled"})
            setStyle('custom-spinner');
            axios.post(`${process.env.REACT_APP_API_URL}updateProfile`,payLoad,header)
            .then(response =>{ 
                
                if(response.status === 200){
                    const obj = {
                        'username': data.username,
                        'cloud_id': cld_id,
                        'name':  data.name,
                        'id': id,
                        'user_type': user_type,
                    };
                    localStorage.setItem('creds', JSON.stringify(obj));
                    if(response.data.url != ""){
                        localStorage.setItem('profileURL', response.data.url);
                    }
                    setStyle('custom-spinner-hide');
                    setAttr({...attr, prof_attr:""})
                    setError({...error, prof_err:""})
                    window.location.href = `${process.env.PUBLIC_URL}/userEdit`;
                    
                }
                
            })
            .catch(err=>{
            
                console.log(err);
                setStyle('custom-spinner-hide');
                setAttr({...attr, prof_attr:""})
                setError({...error, prof_err:"Missing data"})
                
            });
    
    }

    const updatePasswordHandler = (e) => {
        // axios call here 
        //TODO:  also get profile image here of user and store it in profileURL
        e.preventDefault();
        
        const payLoad = {
            'username':uname,
            'cloud_id':cld_id,
            'old_password':password.old_password,
            'new_password':password.new_password
            
        };
        setAttr({...attr, pass_attr:"disabled"})
        setStyle('custom-spinner');
        axios.post(`${process.env.REACT_APP_API_URL}changePassword`,payLoad,header)
        .then(response =>{ 
            
        
            if(response.status === 200){
                setAttr({...attr, pass_attr:""})
                setError({...error, pass_err:""})
                setPassword({
                    ...password,
                    old_password: '',
                    new_password: '',
                });
                setStyle('custom-spinner-hide');
                toast.success("Sucessfully Updated Password");
            }
            
        })
        .catch(err=>{
            
            console.log(err);
            setAttr({...attr, pass_attr:""})
            setError({...error, pass_err:"Wrong Password"})
            setStyle('custom-spinner-hide');

        });
    
    }
    return (
        <Fragment>
            <Breadcrumb parent="User" title="Edit Profile" />
            <div className={"loader-box "+style} >
                <div className="loader">
                <div className="line bg-primary"></div>
                <div className="line bg-primary"></div>
                <div className="line bg-primary"></div>
                <div className="line bg-primary"></div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Update {Password}</h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    
                                    <form>
                                        
                                        <div className="form-group">
                                            <label className="form-label">Old {Password}</label>
                                            <input className="form-control" type="password" name="old_password" value={password.old_password}
                                                        onChange={updatePasswordField} />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">New {Password}</label>
                                            <input className="form-control" type="password" name="new_password" value={password.new_password}
                                                        onChange={updatePasswordField} />
                                        </div>
                                        <div className="text-center">
                                        
                                            <h6 style={{color: "red"}} >{error.pass_err} </h6>
                                        </div>
                                        <div className="form-footer">
                                            <button className="btn btn-primary btn-block" disabled={attr.pass_attr} onClick={updatePasswordHandler}>Update {Password}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <form className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{EditProfile}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Name}</label>
                                                <input className="form-control" name="name" type="text" placeholder="Name"  value={data.name}
                                                        onChange={updateProfileField}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Username}</label>
                                                <input className="form-control" type="text" placeholder="Username" value={uname} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{EmailAddress}</label>
                                                <input className="form-control" name="email" type="email" placeholder="Email" value={data.email}
                                                        onChange={updateProfileField}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{Phone}</label>
                                                <input className="form-control" name="phone_number" type="text" placeholder="Phone" value={data.phone_number}
                                                        onChange={updateProfileField}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="form-label">Profile Image</label>
                                            <ImageUploader
                                                        withIcon={false}
                                                        withPreview={true}
                                                        label=""
                                                        singleImage={true}
                                                        buttonText="Upload Images"
                                                        onChange={onDrop}
                                                        imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                                                        maxFileSize={( 5 * 1048576)} // no. of mbs you want * MB value (mb value is static dont change)
                                                        fileSizeError=" file size is too big"
                                                    />
                                        </div>
                                        <div className="text-center">
                                        
                                            <h6 style={{color: "red"}} >{error.prof_err}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-primary" type="submit" disabled={attr.prof_attr} onClick={updateProfileHandler}>{UpdateProfile}</button>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default UserEdit;