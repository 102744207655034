import React, { Fragment } from 'react';
import Countdown from 'react-countdown';

const CountdownComponent = () => {

    const Completionist = () => <span>{"You are good to go!"}</span>;

        const renderer = ({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
                // Render a completed state
                return <Completionist />;
            } else {
                // Render a countdown
                return <div className="countdown">
                    <ul>
                        <li><span id="days" className="time digits">{days}</span><span
                            className="title">{"days"}</span></li>
                        <li><span id="hours" className="time digits">{hours}</span><span
                            className="title">{"Hours"}</span></li>
                        <li><span id="minutes" className="time digits">{minutes}</span><span
                            className="title">{"Minutes"}</span></li>
                        <li><span id="seconds" className="time digits">{seconds}</span><span
                            className="title">{"Seconds"}</span></li>
                    </ul>
                </div>;
            }
        };

        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth(7);
        var day = d.getDate();
        var coundown = new Date(year ,6, 5).getTime();
        console.log(coundown);

    return (
        <Fragment>
             <Countdown date={coundown} renderer={renderer} />
        </Fragment>
    );
};

export default CountdownComponent;