import React from 'react';

import Modal from 'react-modal';

import './Modal.css';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

const modal = (props) => {

  var subtitle;

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }


    return (
      <div className = "forward-modal">

        <Modal
          isOpen={props.modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={props.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h5> Forward To  </h5>
          <span className="modal-cross" onClick={props.closeModal}>x</span>
            <div className="row form-group">
              <div className="col-xs-8">

                  <input type="text"
                    placeholder= "Enter user or extension"
                    name="to_user"
                    value = {props.toUser}
                    onChange={props.onChangeHandler}/>
              </div>
              <div className="col-xs-4">
                <button  onClick ={props.sendMessage} type="button"
                  >
                  <i className="feather icon-arrow-right"
                  title="Forward"></i>
                </button>
              </div>
          </div>
        </Modal>
      </div>
    );

};

export default modal;
