import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader, Form,FormGroup,Input } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';


import '../../assets/scss/app-styles/domains.scss'
import { ResponsiveEmbed } from 'react-bootstrap';

class Maps extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            selectedDomain: "",
            allDomainsCheckedList: "",
            allTabsCheckedList: "",
            style: 'custom-spinner',
            
        };
    }

    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    GetSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }

    componentDidMount () {
        this.refreshData()

    }

    refreshData = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        };
        axios.post(`${process.env.REACT_APP_API_URL}getDomainsSummary`, payLoad, this.headers)
        .then(response=>{
            
            const domains = (response.data).sort(this.GetSortOrder("name"));
            this.setState({myDomains: domains});
            this.populateDomains(domains);
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    populateDomains = (data) => {
        let items = [];
        data.map((row, index)=>{
            items.push(
                <div key={index} className="domain-checkbox-container">
                    <div  className="checkbox checkbox-primary" >
                        <input id={"checkbox-primary-domain"+index} type="checkbox" value={row.name}
                            onChange={e => this.toggleDomain(e, row.name)} checked={row.netsapien_id === this.props.location.state.id}/>
                        <label htmlFor={"checkbox-primary-domain"+index}>{row.name}</label>
                    </div>
                </div>);
        });

        this.setState({domainsList: items});
    }

    toggleDomain = (evt, domain) => {
        console.log("Domain is ", domain, " flag ", evt.target.checked)
        this.setState({style: 'custom-spinner'});
        let flag = false;
        if(evt.target.checked){
            flag = true
        }

        
        const payLoad = {
            cloud_id : JSON.parse(localStorage.getItem('creds')).cloud_id,
            username: JSON.parse(localStorage.getItem('creds')).username,
            domain: domain,
            flag: flag,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
            netsapien_id: this.props.location.state.id
        }
        
        axios.post(`${process.env.REACT_APP_API_URL}updateDomainsMap`, payLoad, this.headers)
        .then(response=>{

            toast.success( flag ? "Successfully added domain": "Successfully removed domain");
            this.setState({searchValue: ""});
            this.refreshData();
            this.setState({style: 'custom-spinner-hide'});
            
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }
    
    onChangeHandler = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }

    searchChangeHandler = (evt) => {
        this.setState({searchValue: evt.target.value});
        let domains = this.state.myDomains;
        domains = domains.filter((value, index) => {
            const domain = value.name.toLowerCase();
            const searchResult =  evt.target.value.toLowerCase();
            return domain.includes(searchResult);
        });
        this.setState({filteredListOfDomains: domains});
        this.populateDomains(domains);
    }


    render () {
        return (
            <Fragment>
                <Breadcrumb parent="App" title="Maps" />

                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                {
                    this.state.domainsList?

                        <div className="container-fluid main-container">
                            <div className="col-md-1 col-sm-0"></div>
                            <Card className="col-md-10 col-sm-12">
                                <CardHeader>
                                    <h5>Domain To Netsapien Maps</h5>
                                </CardHeader>
                                <CardBody className="dropdown-basic">
                                    <div className="file-content" style={{marginBottom: "20px"}}>
                                        <div className="form-inline">
                                            <div style={{width: "100%"}}>
                                                <i className="fa fa-search"></i>
                                                <Input
                                                    style={{width: "95%"}}
                                                    className="form-control-plaintext"
                                                    type="text"
                                                    value={this.state.searchValue}
                                                    onChange={this.searchChangeHandler}
                                                    placeholder="Search..." 
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div  className="checkbox checkbox-primary" style={{marginLeft:"5px"}} >
                                        <input id={"checkbox-primary-all"} type="checkbox"
                                            onChange={this.checkAll} checked={this.state.checkedAll}/>
                                        <label htmlFor={"checkbox-primary-all"}>Check All</label>
                                    </div> */}

                                    <div className="row">
            
                                        {this.state.domainsList} 
                                        
                                    </div>
                                    
                                    {/* <div className="btn-container">
                                        <button className="btn btn-primary" type="button" onClick={this.saveHandler}>Save</button>
                                    </div> */}
                                </CardBody>
                                
                            </Card>
                                
                            <div className="col-md-1 col-sm-0"></div>
                        </div>
                    :
                    null
                }
            </Fragment>
        );
    }
    
};

export default Maps;