import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import {Redirect} from 'react-router';
import { MDBDataTableV5 } from 'mdbreact';
import axios from 'axios';

import ReactFileReader from 'react-file-reader';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import '../../assets/scss/app-styles/users.scss'


class Domains extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            newRecordClicked: false,
            userIdSelected: null,
            style: 'custom-spinner',
            loading: true,
		    backToIndex: false,
        };
    }
    headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    componentDidMount(){
        this.refreshData();
    }

    refreshData = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        };

        axios.post(`${process.env.REACT_APP_API_URL}getDomainsSummary`, payLoad, this.headers)
        .then(response=>{
            this.createDataTable(response.data);
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

    createDataTable = (dataFromApi) => {

        let columns =[
            {
                label: 'Id',
                field: 'id',
            },
            {
                label: 'Name',
                field: 'name',
            },
            {
                label: 'Delete',
                field: 'edit',
                sort: 'disabled',
            }
        ];

        let dataModified = null;
        if(dataFromApi != null)
        {
            dataModified = dataFromApi.map((row)=>{
                return ({...row, edit:  <div className="edit-column">
                <i className="far fa-trash-alt data-table-icon"
                    onClick={this.deleteHandler} data-id={row.id}></i>
                </div>});
            });

        }
        this.setState({dataTable: {columns: columns, rows: dataModified}});
        this.setState({loading: false});
    }

    deleteHandler = (event) =>{

        confirmAlert({
            title: 'Are you sure you want to delete this Domain?',
            message: '',
            buttons: [
                {
                    label: 'Delete',
                    onClick: () => {
                        const payLoad = {
                            id : event.target.getAttribute("data-id"),
                            user_id: JSON.parse(localStorage.getItem('creds')).id,
                        };
                        this.setState({style: 'custom-spinner'});
                        axios.post(`${process.env.REACT_APP_API_URL}deleteDomains`, payLoad, this.headers)
                        .then(response=>{
                            toast.success("Record Deleted");
                            this.setState({style: 'custom-spinner-hide'});
                            this.refreshData();
                        })
                        .catch(err=>{
                            toast.error(err.message);
                            this.setState({style: 'custom-spinner-hide'});
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                    }
                }
            ]
            
        });
    }

    handleFiles = (files) => {
        var reader = new FileReader();
        const scope = this;
        reader.onload = function (e) {
            // Use reader.result
            const csv = reader.result;
            const lines = csv.split('\n');
            const result = [];
            const headers = ['name'];
            let key = null;
            let value = null;
            
            if(!lines[0].includes(`name`)){
                toast.error("Invalid Format");
                scope.setState({ csvData: null, CSVProcessed: false});
                return;
            }
            for (let i = 1; i < lines.length; i++) {
                
                if (!lines[i])
                    continue;
                lines[i] = lines[i].replaceAll(`"`, ``)
                const obj = {};
                const currentline = lines[i].split(',');
                for (let j = 0; j < headers.length; j++) {
                    key = headers[j];
                    value = currentline[j].replace('\r', '');
                    obj[key] = value;
                }
                result.push(obj);
            }
            
            scope.setState({ csvData: result, CSVProcessed: true });
            
            
            let payLoad = {
                user_id: JSON.parse(localStorage.getItem('creds')).id,
                domains: result
            }
            axios.post(`${process.env.REACT_APP_API_URL}addDomains`, payLoad, scope.headers)
            .then(response=>{
                
                scope.refreshData();
            })
            .catch(err=>{
                if(err.response){
                    if(err.response.data.includes("unique_domains")){
                        toast.error("Some of the records in CSV file already exists. Please add unique records!");
                    }
                    
                } else {
                    toast.error(err.message);
                }
                scope.setState({loading: false});
            });
        };
        
        reader.readAsText(files[0]);
    };

    render() {

        let redirect = null;

        if(this.state.backToIndex){
            redirect = <Redirect to = {{
                    pathname: "/dashboard"
            }} />;
        }

        return(
            <Fragment>
                <Breadcrumb parent="App" title="Domain Management" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                {redirect}

                <div className = "MainIndex">
                    <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'} >
                        <button className='btn btn-dark' style={{marginRight: "5px",float:"right"}} >Import</button>
                    </ReactFileReader>
                    <div className="users-table">
                    <MDBDataTableV5 hover entriesOptions={[10, 20, 30]} entries={10}
                    pagesAmount={4} data={this.state.dataTable} searchTop  searchBottom={false} />

                    </div>
                </div>

            </Fragment>

        );

    }

}

export default Domains;
