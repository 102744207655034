import React from 'react';
import axios from 'axios';
import moment from 'moment';

import Auxi from '../../hoc/Auxi';
import AppLayout from './CustomAppTabLayout';
import { ToastContainer,toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneSquare } from '@fortawesome/free-solid-svg-icons'

import '../../assets/scss/custom-tab-styles/style.scss';

class CallLogs extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            recordType: 'vmail/new',
            voiceMails: [],
            username: "Daniyal",
            loading: false,
            audioSource: '',
            showModal: false,
            data: {}

        };
        this.playPauseRef = [];
    }

    inputChangeHandler = (evt) => {

        const target = evt.target;
        const name = target.name;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        if (value === true) {
            value = "yes";
        }

        if (value === false) {
            value = "no";
        }
        

        let data = this.state.data;
        data[name] = value;
        this.setState({ data });
    }
    
    getDeviceInfo = (userName, uType, password, cloudId) => {
    
        const payLoad = new FormData();
        payLoad.append('device', "sip:" + userName.split("@")[0] + "@" + userName.split("@")[1]);
        payLoad.append('domain', userName.split("@")[1]);
        payLoad.append('cloudId', cloudId);
        
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=device&action=read&utype=${uType}&cloud_username=${userName}&cloud_password=${password}`, payLoad)
        .then(response=>{
            this.setState({username: (response.data[0].aor_user) +'@'+((response.data[0].sub_login).split("@")[1])});

        })
        .catch(err=>{
            toast.error(err.message);
        });
    }

    getCDRs = (uType, userName, domain, password, cloudId) =>{
        var x = new Date();
        const endDate = moment(x).format('YYYY-MM-DD');
        //x.setDate(1);
        x.setMonth(x.getMonth()-1);
        const startDate= moment(x).format('YYYY-MM-DD');
        const payLoad = new FormData();
        payLoad.append('uid', userName);
        payLoad.append('start_date', startDate);
        payLoad.append('end_date', endDate);
        payLoad.append('cloud_id', cloudId);
        payLoad.append('domain', domain);

        this.setState({loading: true});
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=cdr2&action=read&utype=u&cloud_username=${userName}&cloud_password=${password}`, payLoad)
        .then(response=>{
            this.createCDRS(response.data);
            this.setState({loading: false});
        })
        .catch(err=>{
            toast.error(err.message,);
            this.setState({loading: false})
        });

    }
    
    createCDRS = (data) => {

        const cdrs = data.map((row, key)=> {
            let number = row.number
            if(!number){
                return null
            }
            if(number && number.includes("sip")){
                number = number.split(":")[1]
                number = number.split("@")[0] 
            }
            let icons = null;
            if(row.type === "0"){
                //outbound
                icons = <Auxi >
                    <div className="call-status-top">
                        <i className="fas fa-arrow-up"/>
                    </div>
                    <div className="call-status-down">
                        <i className="fas fa-phone-alt"/>
                    </div>
                </Auxi>
            
            } else if (row.type === "1"){
                //inbound
                icons = <Auxi>
                    <div className="call-status-top">
                        <i className="fas fa-arrow-down"/>
                    </div>
                    <div className="call-status-down">
                        <i className="fas fa-phone-alt"/>
                    </div>
                </Auxi>
            } else {
                //missed
                icons = <Auxi>
                    <div className="call-status-top">
                    </div>
                    <div className="call-status-down">
                        <i className="fas fa-phone-slash"></i>
                    </div>
                </Auxi>
            }
            

            let unixDate = moment.unix(row.time_start).toDate();
            let callDate =  moment(unixDate).local().format('MM/DD, h:mm a')
            // const timeAnswerRaw =  moment.utc(data.time_answer).toDate();
            // const timeReleaseRaw =  moment.utc(data.time_release).toDate();

            // const timeStart = moment(date).local().format('h:mm:ss');
            // const timeAnswer = moment(timeAnswerRaw).local().format('h:mm:ss');
            // const timeRelease = moment(timeReleaseRaw).local().format('h:mm:ss');
            return (
                <Auxi>
                    <div className="call-logs-container" onClick={ e => this.toggleIcons("tableRow"+key)}>
                        {icons}
                        <div className="call-name">
                            {row.name ? row.name : number}
                        </div>
                        <div className="call-number">
                            {row.name ? number : null}
                        </div>
                        <div className="call-date">
                            {callDate}
                        </div>
                        <div className="call-duration">
                            {moment.utc(row.duration*1000).format('HH:mm:ss')}
                        </div>
                    </div>
                    <div className="cdr-icons row" style={{display:"none"}}
                        id={"tableRow"+key}>
                            {/* <div className="col-4">
                                <label>Start Time</label> {timeStart}
                            </div>
                            <div className="col-4">
                                <label>Answer Time</label> {timeAnswer}
                            </div>
                            <div className="col-4">
                                <label>Release Time</label> {timeRelease}
                            </div> */}
                            <div className="col-6">
                                <button className="cdr-custom-btns btn btn-dark btn-block"
                                onClick={e => this.MakeCall(number)}>
                                    Call
                                </button>
                            </div>
                            <div className="col-6">
                                <button className="cdr-custom-btns btn btn-dark btn-block"
                                onClick={e => this.messageToNumber(number)}>
                                    Message
                                </button>
                            </div>
                    </div>
                    <hr/>
                </Auxi>
            )
        });

        this.setState({cdrs: cdrs})
    }

    toggleIcons = (id) => {
        const elem = document.getElementById(id)
        if(window.getComputedStyle(elem).display === "none"){
            // hidden
            elem.style.display = "flex";
        } else {
            //visible
            elem.style.display = "none";
        }
    }
    MakeCall = (number) => {
        window.location.href = "sip:"+number+"?dialAction=autoCall"
    }
    messageToNumber = (number) => {
        window.location.href = "cloudsip:"+number+"?dialAction=text"
    }

    visibilityChange=() => {
        if(document.visibilityState === "visible"){
            // infoOnExistingUser will provide the settings data
            document.addEventListener("visibilitychange", this.visibilityChange);

            let url = decodeURIComponent(window.location.href)

            url = url.substring(
                url.lastIndexOf("?username="),
                        url.length
                    );
            this.setState({url: url});

                    let username = url.substring(
                url.lastIndexOf("?username=") + 10,
                        url.lastIndexOf("&password=")
                    );
            let password = url.substring(
                url.lastIndexOf("&password=") + 10,
                url.lastIndexOf("&cloud_id=")
            );
            let cloudId = url.substring(url.lastIndexOf("&cloud_id=") + 10,url.lastIndexOf("&direct="));
            let direct = url.substring(url.lastIndexOf("&direct=") + 8,url.length);
            cloudId = cloudId.replace("*", "");
            password = encodeURIComponent(password);
            
            this.setState({username: username});
            this.setState({password: password});
            this.setState({cloudId: cloudId});
            this.setState({direct: direct});
            const uType = 'u';
            const payLoad = {
                user: username,
                password: password,
                cloud_id: cloudId
            }

            axios.post(`${process.env.REACT_APP_API_NS_URL}/signin`, payLoad)
            .then(response=>{
                username =  response.data.login;
                this.setState({username: username});
                this.setState({uid: response.data.uid});
                this.setState({userDomain: response.data.domain});
                this.getCDRs('s', username, response.data.domain, password, cloudId);
                this.setState({uType: uType});
                this.setState({loggedIn: true})
            })
            .catch(err=>{
                toast.error("Invalid User Login! Error:"+err.message);
                this.setState({loading: false});
            });
        }
    }
    componentDidMount(){

        // infoOnExistingUser will provide the settings data
        document.addEventListener("visibilitychange", this.visibilityChange);

        let url = decodeURIComponent(window.location.href)

        url = url.substring(
            url.lastIndexOf("?username="),
                    url.length
                );
        this.setState({url: url});

                let username = url.substring(
            url.lastIndexOf("?username=") + 10,
                    url.lastIndexOf("&password=")
                );
        let password = url.substring(
            url.lastIndexOf("&password=") + 10,
            url.lastIndexOf("&cloud_id=")
        );
        let cloudId = url.substring(url.lastIndexOf("&cloud_id=") + 10,url.lastIndexOf("&direct="));
        let direct = url.substring(url.lastIndexOf("&direct=") + 8,url.length);
        cloudId = cloudId.replace("*", "");
        password = encodeURIComponent(password);
        
        this.setState({username: username});
        this.setState({password: password});
        this.setState({cloudId: cloudId});
        this.setState({direct: direct});
        const uType = 'u';
        const payLoad = {
            user: username,
            password: password,
            cloud_id: cloudId
        }

        axios.post(`${process.env.REACT_APP_API_NS_URL}/signin`, payLoad)
        .then(response=>{
            username =  response.data.login;
            this.setState({username: username});
            this.setState({uid: response.data.uid});
            this.setState({userDomain: response.data.domain});
            this.getCDRs('s', username, response.data.domain, password, cloudId);
            this.setState({uType: uType});
            this.setState({loggedIn: true})
        })
        .catch(err=>{
            toast.error("Invalid User Login! Error:"+err.message);
            this.setState({loading: false});
        });
        

    }


    render() {

        return(
            <Auxi>
                {/* {
                    this.state.direct === "0" ?
                        <AppLayout cloud_id={this.state.cloudId}
                            display_name = {this.state.username}
                            ownContact = {this.state.ownContact}
                            fromPage = "callLogs"
                            url={this.state.url}/>
                    : null
                } */}

                <div className = "CustomMainIndexApp" style={{marginBottom: "10px", marginTop: "10px"}}>
                    <div className="row voice-message-layout" style={{marginTop: "10px"}}>
                        {this.state.cdrs? this.state.cdrs :null}
                        {/* <div className="call-logs-container">
                            <div className="call-status-top">
                                <i className="fas fa-arrow-down"/>
                            </div>
                            <div className="call-status-down">
                                <i className="fas fa-phone-alt"/>
                            </div>
                            <div className="call-name">
                                name
                            </div>
                            <div className="call-number">
                                number
                            </div>
                            <div className="call-date">
                                26/12/2021, 7:16 pm
                            </div>
                            <div className="call-duration">
                                00:00
                            </div>
                        </div>
                        <hr/>
                        <div className="call-logs-container">
                            <div className="call-status-top">
                                <i className="fas fa-arrow-up"/>
                            </div>
                            <div className="call-status-down">
                                <i className="fas fa-phone-alt"/>
                            </div>
                            <div className="call-name">
                                name
                            </div>
                            <div className="call-number">
                                number
                            </div>
                            <div className="call-date">
                                26/12/2021, 7:16 pm
                            </div>
                            <div className="call-duration">
                                00:00
                            </div>
                        </div>
                        <hr/>
                        <div className="call-logs-container">
                            <div className="call-status-top">
                            </div>
                            <div className="call-status-down">
                                <i className="fas fa-phone-slash"></i>
                            </div>
                            <div className="call-name">
                                name
                            </div>
                            <div className="call-number">
                                number
                            </div>
                            <div className="call-date">
                                26/12/2021, 7:16 pm
                            </div>
                            <div className="call-duration">
                                00:00
                            </div>
                        </div> */}

                    </div>
                </div>
                <ToastContainer />
            </Auxi>

        );

    }

}

export default CallLogs;
