import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import {Redirect} from 'react-router';

import '../../assets/scss/app-styles/users.scss'

class Details extends React.Component {

	constructor(props) {
		super(props);
		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.saveHandler = this.saveHandler.bind(this);
		this.cancelHandler = this.cancelHandler.bind(this);
	}

	state = {
		data: {
            transport: "udp"
		},
		backToIndex: false,
        style: 'custom-spinner',
	};
	headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }

    transportItems = [];

    populateItems = (data) => {
        let items = [];
        items.push(<option value={""}></option>);
        data.map((row)=>(
            items.push(<option value={row}>{row}</option>)
        ));

        return items;

    }

    populateDefaults(){
        axios.get(`${process.env.REACT_APP_API_URL}getDefaults`, this.headers)
            .then(response=>{

                let items = [];

                items = this.populateItems(response.data.transport.Allowed);
                this.transportItems = items;

                items = this.populateItems(response.data.codecorder.Allowed);
                this.codecOrderItems = items;

                items = this.populateItems(response.data.codecorder3g.Allowed);
                this.codecOrder3gItems = items;

            })
            .catch(err=>{
                toast.error(err.message);

            }
        );
    }
    GetSortOrder(prop) {    
        return function(a, b) {    
            if (a[prop] > b[prop]) {    
                return 1;    
            } else if (a[prop] < b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    }

	inputChangeHandler(event) {
		var data = this.state.data;
		data[event.target.name] = event.target.value;
		

        if(event.target.name === "subscriber_id"){
            const index = event.target.selectedIndex;
            data["cloud_id"] = event.target[index].text;
        }
        this.setState({ data });

        if (event.target.name === "domain"){
            if(event.target.value === ""){
                this.setState({extension: ""});
                return;
            }
            this.getExtensions(event.target.value);
        }

	}

    getNetsapiens = (evt) => {
        const payLoad = {
            cloud_id: JSON.parse(localStorage.getItem('creds')).cloud_id,
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        };

        axios.post(`${process.env.REACT_APP_API_URL}netsapienSummary`, payLoad, this.headers)
        .then(response => {
            let items = [];
            items.push(<option value={""}></option>);
            response.data.map((row)=>(
                items.push(<option value={row.id}>{row.client_id}</option>)
            ));
            this.netsapiensItems = items;
            this.setState({netsapiensItems: items})
        }).catch(err => {
            toast.error(err.response.data);
        });
    }

    
    getDomains = () => {
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        };
        axios.post(`${process.env.REACT_APP_API_URL}getDomainsSummary`, payLoad, this.headers)
        .then(response=>{

            const domains = (response.data).sort(this.GetSortOrder("domain"));
            let items = [];
            items.push(<option value={""}></option>);
            domains.map((row)=>(
                items.push(<option value={row.name}>{row.name}</option>)
            ));
            this.setState({domainList: items});
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });

    }
    getExtensions = () => {
        
        this.setState({style: 'custom-spinner'});
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id,
        };

        axios.post(`${process.env.REACT_APP_API_URL}getExtensionsSummary`, payLoad, this.headers)
        .then(response=>{
            const extensions = (response.data).sort(this.GetSortOrder("name"));
            let items = [];
            items.push(<option value={""}></option>);
            extensions.map((row)=>(
                items.push(<option value={row.login}>{row.uid}</option>)
            ));
            this.setState({extensionsList: items});
            this.setState({style: 'custom-spinner-hide'});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({style: 'custom-spinner-hide'});
        });

    }

	componentDidMount() {
		if(!this.props.location.state || JSON.parse(localStorage.getItem('creds')).cloud_id !== "JIVETEL"){
			this.setState({backToIndex: true});
            this.setState({style: 'custom-spinner-hide'});
			return;
		}
        this.populateDefaults();
        this.getNetsapiens();
        this.getDomains();
        this.getExtensions();
		if (this.props.location.state.id) {
			this.setState({style: 'custom-spinner'});
			const payLoad = {
				id: this.props.location.state.id,
				user_id: JSON.parse(localStorage.getItem('creds')).id
			};
			axios.post(`${process.env.REACT_APP_API_URL}profileDetail`, payLoad, this.headers)
			.then(response => {
				var data = this.state.data;
				data = response.data;
				this.setState({ data });
                if(response.data.domain){
                    this.getExtensions(response.data.domain);
                }
				this.setState({style: 'custom-spinner-hide'});
			}).catch(err => {
				toast.error(err.response.data);
                this.setState({style: 'custom-spinner-hide'});
			});
		} else {

			this.setState({style: 'custom-spinner-hide'});
		}

	}

	saveHandler(event) {
		event.preventDefault();
		if (!this.state.data.engine_id || !this.state.data.profilename || 
            !this.state.data.outboundproxyenabled || !this.state.data.outboundproxyhost ||
            !this.state.data.transport) {
            toast.error('Fields with * are mandatory!');
            return;
		}
        if(this.state.data.expires && this.state.data.expires.length > 3){
            toast.error("Expires value can not be greater than 3");
            return;
        }
		this.setState({style: 'custom-spinner'});
		var data = this.state.data;
        data["proxy"] = data.outboundproxyhost;
		this.setState({ data });
		const payLoad = this.state.data;
        payLoad['user_id'] = JSON.parse(localStorage.getItem('creds')).id
        if(!payLoad["domain"]){
            delete payLoad["domain"];
        }
        if(!payLoad["extension"]){
            delete payLoad["extension"];
        } else if (!payLoad["domain"]){
            toast.error("Domain is mandatory with extension");
            return;
        }
		axios.post(`${process.env.REACT_APP_API_URL}profileCreateUpdate`, payLoad, this.headers)
		.then(response => {
			this.setState({ backToSummary: true });
            this.setState({style: 'custom-spinner-hide'});
		}).catch(err => {
            toast.error(err.response.data);
            this.setState({style: 'custom-spinner-hide'});
		});
	}

	cancelHandler() {
		this.setState({ backToSummary: true });
	}

    pushHandler = (event) =>{
        
        if(!this.state[event.target.getAttribute("id")] || this.state[event.target.getAttribute("id")] == ""){
            return;
        }
        let data = this.state.data;
        if(!data[event.target.getAttribute("id")] || data[event.target.getAttribute("id")] == ""){
            data[event.target.getAttribute("id")] = this.state[event.target.getAttribute("id")];
        }
        else{
            if((data[event.target.getAttribute("id")].split(","))
                .filter(element => element == this.state[event.target.getAttribute("id")]? element : null).length == 0){
                
                data[event.target.getAttribute("id")] += "," + this.state[event.target.getAttribute("id")];
            }
        }
        this.setState({data});
    }

    popHandler = (event) => {

        let data = this.state.data;
        if(!data[event.target.getAttribute("id")] || data[event.target.getAttribute("id")] == ""){
            return;
        }

        data[event.target.getAttribute("id")] = data[event.target.getAttribute("id")].substr(0, (data[event.target.getAttribute("id")].lastIndexOf(",")));
        this.setState({data});

    }
    selectChangeHandler  = (event) => {

        this.setState({[event.target.name]: event.target.value});
    }

	render() {

		let redirect = null;
		if (this.state.backToSummary) {
			this.setState({ backToSummary: false });
			redirect = <Redirect to={{
				pathname: '/client_profiles',
			}}/>;
		}
		if(this.state.backToIndex){
			redirect = <Redirect to = {{
				pathname: "/dashboard"
			}} />;
		}

		return (
            <Fragment>
                {redirect}
                <Breadcrumb parent="Client Profiles" title="Details" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <h5>{this.props.location.state && this.props.location.state.id? "Edit Client Profile" : "Add Client Profile"}</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Name
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="profilename"
                                        onChange={this.inputChangeHandler} value={this.state.data.profilename}/>
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left">
                                        Outbound Proxy Host
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="outboundproxyhost"
                                        onChange={this.inputChangeHandler} value={this.state.data.outboundproxyhost}/>
                                </div>
                                
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6 custom-radio">
                                    <label> 
                                        Out Bound Proxy Enabled  
                                    </label> <span> * </span>
                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                            type="radio" value="1" name="outboundproxyenabled"
                                            checked={this.state.data.outboundproxyenabled === "1"}
                                            /> Yes
                                        <input className="margin-left-10"
                                            type="radio" value="0" name="outboundproxyenabled"
                                            checked={this.state.data.outboundproxyenabled === "0"}/> No
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <label className="float-left"> 
                                        Transport  
                                    </label> <span> * </span>
                                    <select  className="form-control"
                                        onChange={this.inputChangeHandler} name="transport" 
                                        value={this.state.data.transport}>
                                        {this.transportItems}
                                    </select>
                                </div>
                                
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Voice Mail Number
                                    </label>
                                    <input type="text" className="form-control" name="voicemailnumber"
                                        onChange={this.inputChangeHandler} value={this.state.data.voicemailnumber}/>
                                </div>

                                <div className="col-md-6 custom-radio">
                                    <label> 
                                        Subscriber For Voicemail  
                                    </label>
                                    <div onChange={this.inputChangeHandler}>
                                        <input className="margin-left-10"
                                            type="radio" value="1" name="subscribeforvoicemail"
                                            checked={this.state.data.subscribeforvoicemail === "1"}
                                            /> Yes
                                        <input className="margin-left-10"
                                            type="radio" value="0" name="subscribeforvoicemail"
                                            checked={this.state.data.subscribeforvoicemail === "0"}/> No
                                    </div>
                                </div>

                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Expires
                                    </label>
                                    <input type="number" className="form-control" name="expires"
                                        onChange={this.inputChangeHandler} value={this.state.data.expires}/>
                                </div>
                                {
                                    this.state.netsapiensItems? 
                                        <div className="col-md-6">
                                            <label> Netsapien  </label> <span> * </span>
                                            <select  className="form-control"
                                                onChange={this.inputChangeHandler} name="engine_id" 
                                                value={this.state.data.engine_id}>
                                                {this.state.netsapiensItems}
                                            </select>
                                        </div>
                                    : null
                                }
                            </div>

                            <div className="row form-group">
                                {
                                    this.state.domainList?
                                        <div className="col-md-6">
                                            <label className="float-left"> 
                                                Domain  
                                            </label>
                                            <select  className="form-control"
                                                onChange={this.inputChangeHandler} name="domain" 
                                                value={this.state.data.domain}>
                                                {this.state.domainList}
                                            </select>
                                        </div>
                                    : null
                                }
                                
                                    

                                {
                                    this.state.extensionsList?

                                        <div className="col-md-6">
                                            <label className="float-left">
                                                Extension
                                            </label>
                                            <select  className="form-control"
                                                onChange={this.inputChangeHandler} name="extension" 
                                                value={this.state.data.extension}>
                                                {this.state.extensionsList}
                                            </select> 
                                        </div>

                                    :null
                                }
                            </div>

                            <div className="row form-group order-component">

                                <div className="col-md-6">
                                    <label className="float-left"> Codec Order  </label>
                                    <input type="text" className="form-control" name="codecorder"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.codecorder} disabled/>
                                </div>

                                <div className="col-md-custom" style={{marginTop: "29px"}}>
                                    <select  className="form-control"
                                        onChange={this.selectChangeHandler} name="codecorder">
                                        {this.codecOrderItems}
                                    </select>

                                </div>
                                <div className="col-md-2" style={{marginTop: "29px"}}>
                                    
                                    <button  type="button" onClick={this.pushHandler} id="codecorder"
                                        className="button-custom btn shadow-2 btn-custom btn-width">
                                        Add
                                    </button>

                                </div>

                                <div className="col-md-2" style={{marginTop: "29px"}}>
                                    
                                    <button  type="button" onClick={this.popHandler} id="codecorder"
                                        className="button-custom btn shadow-2 btn-custom btn-width">
                                        Remove
                                    </button>

                                </div>

                            </div>

                            <div className="row form-group order-component">

                                <div className="col-md-6">
                                    <label className="float-left"> Codec Order 3G  </label>
                                    <input type="text" className="form-control" name="codecorder3g"
                                    onChange={this.inputChangeHandler}
                                    value = {this.state.data.codecorder3g} disabled/>
                                </div>

                                <div className="col-md-custom" style={{marginTop: "29px"}}>
                                    <select  className="form-control"
                                        onChange={this.selectChangeHandler} name="codecorder3g">
                                        {this.codecOrder3gItems}
                                    </select>

                                </div>
                                <div className="col-md-2" style={{marginTop: "29px"}}>
                                    
                                    <button  type="button" onClick={this.pushHandler} id="codecorder3g"
                                        className="button-custom btn shadow-2 btn-custom btn-width">
                                        Add
                                    </button>

                                </div>

                                <div className="col-md-2" style={{marginTop: "29px"}}>
                                    
                                    <button  type="button" onClick={this.popHandler} id="codecorder3g"
                                        className="button-custom btn shadow-2 btn-custom btn-width">
                                        Remove
                                    </button>

                                </div>

                            </div>

                            <div className="row form-group">
                                <div className="col-md-12">

                                    <button type="button" onClick={this.cancelHandler} className="button-custom btn shadow-2 btn-secondary btn-width">
                                        CANCEL
                                    </button>
                                    <button type="button" onClick={this.saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        SAVE
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>
            </Fragment>
		);

	}
}

export default Details;
